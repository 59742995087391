import { get, post, download, del, put } from './index'

/**
 * 获取月份差异列表
 *
 * @param data
 * @returns {Promise<unknown>}
 */
export const statisticsMonth = data => {
  return get('/original/order/statistics/month', data).then(res => res)
}

/**
 * 获取类目差异列表
 *
 * @param data
 * @returns {Promise<unknown>}
 */
export const statisticsCondition = data => {
  return get('/original/order/statistics/category', data).then(res => res)
}

/**
 * 类目统计下载
 *
 * @param data
 * @returns {Promise<unknown>}
 */
export const statisticsDownload = data => {
  return download('/original/order/statistics/download', data).then(res => res)
}

/**
 * 刷新差异列表
 *
 * @param data
 * @returns {Promise<unknown>}
 */
export const refreshDifference = data => {
  return post('/original/order/refresh/difference', data).then(res => res)
}
